import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { NgxEchartsModule } from 'ngx-echarts';

import { LoadingAnimationComponent } from './components/loading-animation/loading-animation.component';
import { CalendarWidgetComponent } from './components/widgets/calendar-widget/calendar-widget.component';
import { HealthTrackingWidgetComponent } from './components/widgets/health-tracking-widget/health-tracking-widget.component';
import { BasicElementsRendererComponent } from './components/basic-elements-renderer/basic-elements-renderer.component';
import { ExerciseSecuenceConstructorComponent } from './components/exercise-secuence-constructor/exercise-secuence-constructor.component';
import { CalendarActionListManagerComponent } from './components/calendar-action-list-manager/calendar-action-list-manager.component';
import { ProfileFieldsViewComponent } from './components/profile-fields-view/profile-fields-view.component';
import { FitbitDevicePairingComponent } from './components/fitbit-device-pairing/fitbit-device-pairing.component';
import { BasicElementsConfigRendererComponent } from './components/basic-elements-config-renderer/basic-elements-config-renderer.component';
import { BasicElementsConfigPaletteComponent } from './components/basic-elements-config-palette/basic-elements-config-palette.component';
import { StatsChartComponent } from './components/stats-chart/stats-chart.component';
import { ActionSequenceSchedulerComponent } from './components/action-sequence-scheduler/action-sequence-scheduler.component';

import { splitChunkPipe } from './pipes/split-chunk.pipe'
import { roleCutAppSlugPipe } from './pipes/role-cut-app-slug.pipe';
import { OrderBy } from './pipes/order-by.pipe';
import { FilterPipe } from './pipes/filter.pipe';

//////////////////Basic Components///////////////////
import { StringFieldElementComponent } from './components/basic/string-field-element/string-field-element.component';
import { DateFieldElementComponent } from './components/basic/date-field-element/date-field-element.component';
import { NumericFieldElementComponent } from './components/basic/numeric-field-element/numeric-field-element.component';
import { PickOneRadioElementComponent } from './components/basic/pick-one-radio-element/pick-one-radio-element.component';
import { HeightInputElementComponent } from './components/basic/height-input-element/height-input-element.component';
import { HeartRateZonesElementComponent } from './components/basic/heart-rate-zones-element/heart-rate-zones-element.component';
import { WeightInputElementComponent } from './components/basic/weight-input-element/weight-input-element.component';
import { YesNoInputElementComponent } from './components/basic/yes-no-input-element/yes-no-input-element.component';
import { ListFieldElementComponent } from './components/basic/list-field-element/list-field-element.component';
import { ContentRawElementComponent } from './components/basic/content-raw-element/content-raw-element.component';
import { ImageElementComponent } from './components/basic/image-element/image-element.component';
import { VideoElementComponent } from './components/basic/video-element/video-element.component';

//////////////////Basic Components Config///////////////////
import { ContentRawElementConfigComponent } from './components/basicconfiguration/content-raw-element-config/content-raw-element-config.component';
import { ImageElementConfigComponent } from './components/basicconfiguration/image-element-config/image-element-config.component';
import { NumberElementConfigComponent } from './components/basicconfiguration/number-element-config/number-element-config.component';
import { StringElementConfigComponent } from './components/basicconfiguration/string-element-config/string-element-config.component';
import { VideoElementConfigComponent } from './components/basicconfiguration/video-element-config/video-element-config.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgxEchartsModule
  ],
  declarations: [
    //Basic Components
    StringFieldElementComponent,
    DateFieldElementComponent,
    HeightInputElementComponent,
    HeartRateZonesElementComponent,
    NumericFieldElementComponent,
    PickOneRadioElementComponent,
    YesNoInputElementComponent,
    WeightInputElementComponent,
    ListFieldElementComponent,
    ContentRawElementComponent,
    ContentRawElementConfigComponent,
    ImageElementConfigComponent,
    NumberElementConfigComponent,
    StringElementConfigComponent,
    VideoElementConfigComponent,
    ImageElementComponent,
    VideoElementComponent,
    //Components
    LoadingAnimationComponent,
    BasicElementsRendererComponent,
    ExerciseSecuenceConstructorComponent,
    CalendarActionListManagerComponent,
    ProfileFieldsViewComponent,
    FitbitDevicePairingComponent,
    BasicElementsConfigRendererComponent,
    BasicElementsConfigPaletteComponent,
    StatsChartComponent,
    ActionSequenceSchedulerComponent,
    //Widgets
    CalendarWidgetComponent,
    HealthTrackingWidgetComponent,
    //Pipes
    splitChunkPipe,
    roleCutAppSlugPipe,
    FilterPipe,
    OrderBy
  ],
  exports: [
    ContentRawElementConfigComponent,
    ImageElementConfigComponent,
    NumberElementConfigComponent,
    StringElementConfigComponent,
    StringFieldElementComponent,
    DateFieldElementComponent,
    HeightInputElementComponent,
    HeartRateZonesElementComponent,
    NumericFieldElementComponent,
    PickOneRadioElementComponent,
    YesNoInputElementComponent,
    WeightInputElementComponent,
    ListFieldElementComponent,
    ContentRawElementComponent,
    LoadingAnimationComponent,
    CalendarWidgetComponent,
    HealthTrackingWidgetComponent,
    splitChunkPipe,
    roleCutAppSlugPipe,
    FilterPipe,
    OrderBy,
    BasicElementsRendererComponent,
    ExerciseSecuenceConstructorComponent,
    CalendarActionListManagerComponent,
    ProfileFieldsViewComponent,
    FitbitDevicePairingComponent,
    BasicElementsConfigRendererComponent,
    BasicElementsConfigPaletteComponent,
    ImageElementConfigComponent,
    VideoElementConfigComponent,
    VideoElementComponent,
    ImageElementComponent,
    StatsChartComponent,
    ActionSequenceSchedulerComponent
  ]
})
export class SharedModule {}
