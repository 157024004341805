// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

/*export const API_URL = 'https://reslnmyhapp01.research.chop.edu';
export const APP_SLUG = 'chop';

export const BASIC_HEADERS = {
	'Content-Type':  'application/json'
}*/


export const API_URL = 'https://myheart.research.chop.edu';
export const APP_SLUG = 'chop';

export const BASIC_HEADERS = {
	'Content-Type':  'application/json'
}


/*export const API_URL = 'http://http-nutromatic-ocn-io-3.moesif.net';
export const APP_SLUG = 'chop';

export const BASIC_HEADERS = {
	'Content-Type':  'application/json',
	'X-Moesif-Application-Id' : 'eyJhcHAiOiIzNDU6MjM3IiwidmVyIjoiMi4wIiwib3JnIjoiMzUxOjEyMiIsImlhdCI6MTUzMDQ4OTYwMH0.CMrVQ1tjEZFNdcu-RpsTYFlHj7UeD9fBw60ufk3_zLs',
}
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.