import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
 
const TOKEN_KEY = 'ohn-auth-token';
 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 
  authenticationState: any = new BehaviorSubject(false);
 
  constructor(private platform: Platform) { 
    this.platform.ready().then(() => {
      this.checkToken();
    });
  }
 
  checkToken() {
    if (localStorage.getItem(TOKEN_KEY)) {
      this.authenticationState.next(true);
    }
  }
 
  login(token:string) {
    localStorage.setItem(TOKEN_KEY, 'Bearer ' + token)
    this.authenticationState.next(true);
  }
 
  logout() {
    localStorage.removeItem(TOKEN_KEY);
    this.authenticationState.next(false);
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
 
}