import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';

@Component({
  selector: 'app-basic-elements-config-palette',
  templateUrl: './basic-elements-config-palette.component.html',
  styleUrls: ['./basic-elements-config-palette.component.scss'],
})
export class BasicElementsConfigPaletteComponent implements OnInit {

	@Output() newDescriptionElement = new EventEmitter();

	elementsPreset : any = {
		textElement : <OHNElement>{
			"element_slug" : "",
			"text" : "",
			"_cls" : "contentElement",
			"controller" : "contentElementController",
			"value" : "",
			"settings" : {},
			"config": {},
			"elements" : []
		},
		imageElement : <OHNElement>{
			"element_slug" : "",
			"text" : "",
			"_cls" : "imageElement",
			"controller" : "imageElementController",
			"value" : "",
			"settings" : {},
			"config": {},
			"elements" : [],
			"image_url" : ""
		},
		videoElement : <OHNElement>{
			"element_slug" : "",
			"text" : "",
			"_cls" : "videoElement",
			"controller" : "videoElementController",
			"value" : "",
			"settings" : {},
			"config": {},
			"elements" : [],
			"video_url" : ""
		},
		stringElement : <OHNElement>{
			"element_slug" : "",
			"text" : "",
			"_cls" : "stringFieldElement",
			"controller" : "stringFieldController",
			"value" : "",
			"settings" : {},
			"config": {},
			"elements" : []
		},
		numberElement : <OHNElement>{
			"element_slug" : "",
			"text" : "",
			"_cls" : "numericFieldElement",
			"controller" : "numericFieldController",
			"value" : "",
			"settings" : {},
			"config": {},
			"elements" : []
		}
	}

  constructor() { }

  ngOnInit() {}

  returnElement(type: string) {
  	this.newDescriptionElement.emit(this.elementsPreset[type]);
  }

}
