import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-numeric-field-element',
  templateUrl: './numeric-field-element.component.html',
  styleUrls: ['./numeric-field-element.component.scss'],
})
export class NumericFieldElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {
    this.el.value = this.el.value || null;
  }

}
