import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { OhnApiService } from '../../services/ohn-api.service';
import { OHNElement, OHNUser } from '../../models/ohn-instances';
import * as _ from 'underscore/underscore';

import { HealthTrackingEntryPage } from '../health-tracking-entry/health-tracking-entry.page';

@Component({
  selector: 'app-health-tracking',
  templateUrl: './health-tracking.page.html',
  styleUrls: ['./health-tracking.page.scss'],
})
export class HealthTrackingPage implements OnInit {

	parentElement: OHNElement;

	loading: boolean = true;

  constructor(
  	private router: Router,
    private activatedRoute: ActivatedRoute,
    private ohnApi: OhnApiService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
  	let parentSlug: string = this.activatedRoute.snapshot.paramMap.get('elementSlug');
    this.loadParentElement(parentSlug);
  }

  loadParentElement(slug: string) {
  	this.loading = true;
    this.ohnApi.getElement(slug, 3).subscribe(parentElement => {
      this.parentElement = parentElement;
      this.loadElementsState(slug);
      this.loading = false;
    });
  }

  loadElementsState(slug: string) {
  	this.ohnApi.getElementState(slug).subscribe(state => {
  		this.updateValuesFromState(state.value);
      this.loading = false;
    });
  }

  updateValuesFromState(state: any) {
  	_.each(this.parentElement.elements, (element)=>{
  		element.value = state[element.element_slug] ? state[element.element_slug].value : '---';
  	});
  }

  async openNewEntry() {
    const entryPage = await this.modalController.create({
      component: HealthTrackingEntryPage,
      componentProps: {
      	'parentElement' : this.parentElement
	    },
      backdropDismiss: false
    });

    entryPage.onDidDismiss().then((data) => {
    	console.log(data);
    	this.loadElementsState(this.parentElement.element_slug);
		})

    return await entryPage.present();
  }

}
