export interface OHNElement {
	element_slug?: string,
	text: string,
	settings?: any,
	controller: string,
	value?: any,
	config?: any,
	elements?: OHNElement[],
	prescribed? : number,
	video_url? : string,
	isVariable? : boolean,
	id? : string
}

export interface OHNUser {
	username: string,
	name: string,
	email: string,
	phone: string,
	smart_contract: string,
	profiles: any,
  roles: string[],
  app_slug: string,
  order?: number,
  timezone? : number
}

export interface OHNUserRole {
	id? : string,
	name : string,
	permissions : string[],
	app_slug? : string,
	dependants? : string[],
	roleProfileElements? : OHNElement[]
}

export class OHNCalendarEvent {
	id : string;
	title: string;
	startTime: Date;
	endTime: Date;
	description: string;
	element_slug: string;
	completed: boolean;
	allDay: boolean;
	content: string;
	rating: number;
	contentObject: OHNCalendarActionList;

	constructor(event: any = {
		id : '',
		title : '',
		description : '',
		startTime : new Date(),
		endTime : new Date(),
		element_slug : '',
		completed : false,
		allDay: false,
		rating: 0,
		content: '{}'
	}) {
      this.id = event.id;
      this.title = event.title;
      this.description = event.description;
      this.startTime = new Date(event.startTime);
      this.endTime = new Date(event.endTime);
      this.element_slug = event.element_slug;
      this.completed = event.completed,
      this.allDay = event.allDay,
      this.content = event.content;
      this.rating = event.rating;
      this.contentObject = this.content && this.content != '{}' ? JSON.parse(this.content) :  <OHNCalendarActionList>{
	      text : "",
	      icon: "",
	      description : [],
	      actions : []
	    };
  }

	normalizeTimes(): void {
		this.startTime = new Date(this.startTime),
		this.endTime = new Date(this.endTime)
	};

	mergeDateAndTimes(date: Date, startTime: Date, endTime: Date): void {
		this.endTime = new Date(date);
  	this.endTime.setHours(endTime.getHours(), endTime.getMinutes());
  	this.startTime = new Date(date);
  	this.startTime.setHours(startTime.getHours(), startTime.getMinutes());
	}
}

export class OHNCalendarAction {
	text : string;
	description: OHNElement[];
	done : boolean;
	trackingParameters : OHNElement[];
	repeat : number;
	id? : string;
}

export class OHNCalendarActionList {
	id? : string;
	text : string;
	icon: string;
	type: string;
	description : OHNElement[];
	actions : OHNCalendarAction[];

	constructor(id: string) {
		this.id = id;
		this.text = '';
	  this.icon = '';
	  this.type = '';
	  this.description = [];
	  this.actions = [];
  }
}

export class OHNCalendarSequence {
	id? : string;
	title : string;
	description : string;
	sequence : {
		[key: number]: string[]
	}

	constructor(id: string) {
		this.id = id;
		this.title = '';
		this.description = '';
		this.sequence = {};
  }
}