import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage';
import { OhnApiService } from './services/ohn-api.service';
import { SharedModule } from './app.shared.module';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';

//////////////////Pages///////////////////
import { NewUserPage } from './secured/new-user/new-user.page';
import { NewCalendarEventPage } from './secured/new-calendar-event/new-calendar-event.page';
import { CalendarEventViewPage } from './secured/calendar-event-view/calendar-event-view.page';
import { HealthTrackingEntryPage } from './secured/health-tracking-entry/health-tracking-entry.page';
import { CalendarSequenceManagerPage } from './secured/calendar-sequence-manager/calendar-sequence-manager.page';
import { CalendarEventResultPage } from './secured/calendar-event-result/calendar-event-result.page';
import { PopoverComponent } from './components/basic/popover/popover.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';



//////////////////Components///////////////////

@NgModule({
  declarations: [
    AppComponent,
    NewCalendarEventPage,
    NewUserPage,
    CalendarEventViewPage,
    HealthTrackingEntryPage,
    CalendarSequenceManagerPage,
    CalendarEventResultPage,
    PopoverComponent,
    DisclaimerComponent
  ],
  entryComponents: [
    NewCalendarEventPage,
    NewUserPage,
    CalendarEventViewPage,
    HealthTrackingEntryPage,
    CalendarSequenceManagerPage,
    CalendarEventResultPage,
    PopoverComponent,
    DisclaimerComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule.forRoot({mode: 'md'}), 
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    IonicStorageModule.forRoot(),
    MarkdownModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    OhnApiService,
    MarkdownModule,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}