import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-raw-element',
  templateUrl: './content-raw-element.component.html',
  styleUrls: ['./content-raw-element.component.scss'],
})
export class ContentRawElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {
  	this.el.value = this.el.value || this.el.text;
  }

}
