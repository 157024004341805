import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';

@Component({
  selector: 'app-fitbit-device-pairing',
  templateUrl: './fitbit-device-pairing.component.html',
  styleUrls: ['./fitbit-device-pairing.component.scss'],
})
export class FitbitDevicePairingComponent implements OnInit {

	@Input() el: OHNElement;

	pairingCode : string = "";
  errorMsg: string = "";

  devices: any = [];

	loading : boolean = false;

  constructor(
  	private ohnApi: OhnApiService
  ) {}

  ngOnInit() {
    this.loadPairedDevices();
  }

  loadPairedDevices() {
    this.loading = true;
    this.ohnApi.getFitbitPairedDevices().subscribe(devices => {
      console.log(devices);
      this.devices = devices;
      this.loading = false;
      this.errorMsg = "";
    });
  }

  forgetPairedDevice(uuid:string) {
    this.loading = true;
    this.ohnApi.forgetFitbitDevice(uuid).subscribe(devices => {
      console.log(devices);
      this.loadPairedDevices();
    });
  }

  getPairingCode() {
  	this.loading = true;
  	this.ohnApi.getFitbitPairingCode().subscribe(code => {
      console.log(code);
      this.pairingCode = code.device_code;
      this.loading = false;
    });
  }

  setPairingCode() {
    this.loading = true;
    this.ohnApi.setFitbitPairingCode(this.pairingCode).subscribe(code => {
      console.log(code);
      this.loadPairedDevices();
    },
    error => {
      this.errorMsg = error.error.message;
      this.loading = false;
    });
  }

}
