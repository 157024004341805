import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pick-one-radio-element',
  templateUrl: './pick-one-radio-element.component.html',
  styleUrls: ['./pick-one-radio-element.component.scss'],
})
export class PickOneRadioElementComponent implements OnInit {

	@Input() el: any;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {
    this.el.value = this.el.value || null;
  }

}