import { Component, OnInit, Input } from '@angular/core';
import { OHNCalendarSequence, OHNCalendarActionList } from '../../models/ohn-instances';
import { Subject } from 'rxjs';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-action-sequence-scheduler',
  templateUrl: './action-sequence-scheduler.component.html',
  styleUrls: ['./action-sequence-scheduler.component.scss'],
})


export class ActionSequenceSchedulerComponent implements OnInit {

	@Input() calendarSequenceList: OHNCalendarSequence[];

	@Input() calendarActions : OHNCalendarActionList[];

	@Input() trackingParameterVariables : Subject<any>;

	selectedSequence: OHNCalendarSequence;

	variableFields : any[] = [];

  constructor() {}

  ngOnInit() {

  }

  selectSequence(event: any) {
  	this.variableFields = [];
  	let uniqActionIds: string[] = [];
  	let sequenceArrays : any = _.values(this.selectedSequence.sequence);
  	_.each(sequenceArrays, (a)=>{
  		uniqActionIds = _.union(uniqActionIds, a);
  	});
  	let uniqActions: OHNCalendarActionList[] = _.filter(this.calendarActions, (a)=>{ return _.contains(uniqActionIds, a.id)}); 	

  	_.each(uniqActions, (al, ali)=>{
  		_.each(al.actions, (a, ai)=>{
  			_.each(a.trackingParameters, (t, ti)=>{
  				if (t.isVariable) {
  					this.variableFields.push({
  						actionListId : al.id,
  						actionListText : al.text,
  						acttionListIndex : ali,
  						actionId : a.id,
  						actionText : a.text,
  						actionIndex : ai,
  						trackingParamId : t.id,
  						trackingParamText : t.text,
  						trackingParameterIndex : ti,
  						prescribed : t.prescribed
  					});
  				}
  			});
  		});
  	});
  }

  schedule() {
  	this.trackingParameterVariables.next({sequence: this.selectedSequence, variables : this.variableFields});
  }
}
