import { Component, OnInit, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-stats-chart',
  templateUrl: './stats-chart.component.html',
  styleUrls: ['./stats-chart.component.scss'],
})
export class StatsChartComponent implements OnInit {

	@Input() chartData : any;

	constructor() { }

	ngOnInit() {

		console.log(this.chartData);

		let xVals = this.chartData.map(function (item) {
			return item["t"];
		});

		let yVals = this.chartData.map(function (item) {
			return item["h"];
		});

		console.log(xVals);
		console.log(yVals);

		this.chartData =  {
			xAxis: {
				data: xVals
			},
			yAxis: {
				type: 'value'
			},
			series: [{
				data: yVals,
				type: 'line',
            	symbol: 'none',
				lineStyle: {color: '#33a0bb'}
			}]
		}

	}

}