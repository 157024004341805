import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-content-raw-element-config',
  templateUrl: './content-raw-element-config.component.html',
  styleUrls: ['./content-raw-element-config.component.scss'],
})
export class ContentRawElementConfigComponent implements OnInit {
	
	@Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
