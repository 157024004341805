import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})

export class PopoverComponent implements OnInit {

	@Input() inputData: any;
	@Input() dataType: string;

	constructor(public navParams: NavParams, public popoverController: PopoverController) {
		this.inputData = navParams.get('inputData');
		this.dataType = navParams.get('dataType');
	}

	async selectItem(item: any) {
		await this.popoverController.dismiss(item);
	}

	ngOnInit() {}

}
