import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError , empty, BehaviorSubject} from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { OhnService } from './ohn.service';
import { environment, API_URL, APP_SLUG, BASIC_HEADERS } from '../../environments/environment';
import { OHNElement, OHNUser, OHNUserRole } from '../models/ohn-instances';


const TOKEN_KEY = 'ohn-auth-token';

@Injectable({
  providedIn: 'root'
})

export class OhnApiService {

	httpOptions: any = {
		headers: new HttpHeaders(BASIC_HEADERS)
	}

	locale : string = 'en';

	tokenReceivedFromStorage : any =  new BehaviorSubject(false);

  constructor(private ohnService: OhnService, private http: HttpClient, private auth: AuthenticationService, private platform : Platform, private storage: Storage) {

  		this.platform.ready().then(() => {
	      this.getLocale();
	    });

	    this.auth.authenticationState.subscribe(state => {
        if (state) this.getToken();
      });

  }

  getToken() {
    if (localStorage.getItem(TOKEN_KEY)) {
    	let headers: any = BASIC_HEADERS;
    	headers['Authorization'] = localStorage.getItem(TOKEN_KEY);
      this.httpOptions = {
				headers: new HttpHeaders(headers)
			}
			this.tokenReceivedFromStorage.next(true);
		}
	}

	setToken(token: string) {
		let headers: any = BASIC_HEADERS;
    headers['Authorization'] = localStorage.getItem(TOKEN_KEY);
		this.httpOptions = {
			headers: new HttpHeaders(headers)
		}
	}

	getLocale() {
    this.storage.get('ohn-locale').then(res => {
    	this.locale = res ? res : 'en';
    })
	}

	authService(postInfo: any): Observable<any>  {

		postInfo['app_slug'] = APP_SLUG;

		return this.http.post(API_URL+ '/auth/register', postInfo, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			})
    )
	}

	getApp() : Observable<any> {
    return this.http.get(API_URL+ '/' + APP_SLUG + '/app/'+ this.locale, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting App', 'gettingApp');
				return empty();
			})
    )
	}

	getUserList(): Observable<any> {
    return this.http.get(API_URL+ '/' + APP_SLUG + '/user/list', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting user list', 'gettingUserList');
				return empty();
			})
    )
  }

  getAvailableRoles(): Observable<any> {
    return this.http.get(API_URL+ '/' + APP_SLUG + '/role/list', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting user role list', '');
				return empty();
			})
    )
  }

  getMe(): Observable<any> {
    return this.http.get(API_URL+ '/' + APP_SLUG + '/user', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Me', '');
				return empty();
			})
    )
  }

	getElement(elementSlug: string, depth: number): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/'+ this.locale + '/' + depth, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element ' + elementSlug, '');
				return empty();
			})
    )
	}

	setElement(elementSlug: string, data: any): Observable<any>  {

		return this.http.put(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/'+ this.locale, data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element ' + elementSlug, '');
				return empty();
			})
    )
	}

	getElementState(elementSlug: string): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element state ' + elementSlug + ' for current user ', '');
				return empty();
			})
    )
	}

	getElementStateSc(elementSlug: string, smartContract: string): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementStateRepeatableSc(elementSlug: string, smartContract: string): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state/repeating/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting repeating element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	setElementState(elementSlug: string, data: any): Observable<any>  {

		return this.http.put(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element state ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	patchElementState(elementSlug: string, data: any): Observable<any>  {

		return this.http.patch(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Patching element state ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	setElementStateSc(elementSlug: string, data: any, smartContract: string): Observable<any>  {

		data['smart_contract'] = smartContract;

		return this.http.put(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Setting element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementHistory(elementSlug: string, period: string, page: number): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/period/' + period + '/' + page, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	getElementHistoryByDates(elementSlug: string, dates: string[]): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/period/' + dates[0] + '/' + dates[1], this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	getElementHistoryByDatesSc(elementSlug: string, dates: string[], smartContract: string): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/period/' + dates[0] + '/' + dates[1], this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	getElementHistorySc(elementSlug: string, period: string, page: number, smartContract: string): Observable<any>  {

		return this.http.get(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/period/' + period + '/' + page, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementHistoryScCostil(elementSlug: string, period: string, page: number, smartContract: string): Observable<any>  {

		return this.http.get(API_URL+ '/ohnapi/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/period/' + period + '/' + page, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getElementHistoryByDatesScCostil(elementSlug: string, dates: string[], smartContract: string): Observable<any>  {

		return this.http.get(API_URL+ '/ohnapi/' + APP_SLUG + '/' + elementSlug + '/history/smart_contract/' + smartContract + '/period/' + dates[0] + '/' + dates[1], this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting element history ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	deleteElementStateSc(elementSlug: string, data: any, smartContract: string): Observable<any>  {

		data['smart_contract'] = smartContract;

		let options : any = this.httpOptions;

		options['body'] = data;

		return this.http.delete(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/state', options)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Deleting element state ' + elementSlug + ' for smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	addNewUser(user: OHNUser) : Observable<any>  {
    user.app_slug = APP_SLUG;
    return this.http.put(API_URL+ '/' + APP_SLUG + '/user', user, this.httpOptions)
    .pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Creating user ' + user.email, 'addingNewUser');
				return empty();
			})
    )
  }

  grantUserRole(data: any) : Observable<any> {
  	return this.http.put(API_URL+ '/' + APP_SLUG + '/user/role', data, this.httpOptions)
    .pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Granting role to user ' + data.username, '');
				return empty();
			})
    )
  }

  patchUser(user: OHNUser) : Observable<any>  {
    user.app_slug = APP_SLUG;
    return this.http.patch(API_URL+ '/' + APP_SLUG + '/user', user, this.httpOptions)
    .pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Patching user account' + user.email, '');
				return empty();
			})
    )
  }

  deleteUser(smartContract: string): Observable<any>  {
		return this.http.delete(API_URL+ '/' + APP_SLUG + '/user/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Deleting user with smart contract ' + smartContract, '');
				return empty();
			})
    )
	}

	getFitbitPairedDevices() : Observable<any> {
		return this.http.get(API_URL+ '/device', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Paired Devices', '');
				return empty();
			})
		)
	}

	forgetFitbitDevice(uuid: string) : Observable<any> {
				
		let options : any = this.httpOptions;

		options['body'] = {device_uuid: uuid};

		return this.http.delete(API_URL + '/device', options)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Forgetting FitBit device UUID: ' + uuid, '');
				return empty();
			})
    	)
	}

	getFitbitPairingCode() : Observable<any> {
		return this.http.get(API_URL + '/pair_device', this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting Pairing Token', '');
				return empty();
			})
		)
	}

	setFitbitPairingCode(device_code: string) : Observable<any> {

		return this.http.post(API_URL + '/device', {code: device_code}, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			})
		)
	}

	getPatientReport(elementSlug : string, smartContract: string) : Observable<any> {
    return this.http.get(API_URL+ '/' + APP_SLUG + '/user/share_report/' + elementSlug + '/raw/smart_contract/' + smartContract, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Getting patient report', '');
				return empty();
			})
    )
	}

	searchElementQuery(elementSlug: string, data: any): Observable<any>  {

		return this.http.put(API_URL+ '/' + APP_SLUG + '/' + elementSlug + '/query', data, this.httpOptions)
		.pipe(
			map(res => {
				return res;
			}),
			catchError(err => {
				this.errorHandler(err, 'Searching query ' + elementSlug + ' for current user', '');
				return empty();
			})
    )
	}

	errorHandler(error: any, context: string, contextEvent: string) {
		console.log(context);
		switch (error.status) {
		  case 401:
		      this.auth.logout();
		    break;
		  case 403:
		      this.auth.logout();
		    break;
		  case 502:
		      this.ohnService.stopLoading();
		    break;
		  case 405:
		      this.ohnService.stopLoading();
		      switch (contextEvent) {
		      	case "addingNewUser":
		      		this.ohnService.showAlert('User Exists', 'User already exists. Choose different username.');
		      		break;
		      }
		    break;
		  
		  default:
		    break;
		}
	}
}