import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-date-field-element',
  templateUrl: './date-field-element.component.html',
  styleUrls: ['./date-field-element.component.scss'],
})
export class DateFieldElementComponent implements OnInit {

  @Input() el: OHNElement;
  @Input() viewMode : string;

  constructor() { }

  ngOnInit() {
  	this.el.value = this.el.value || null;
  }

}
