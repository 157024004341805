import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { OhnApiService } from './services/ohn-api.service';
import { OhnService } from './services/ohn.service';
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
 
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { OHNElement, OHNUser } from './models/ohn-instances';

import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';

import * as _ from 'underscore/underscore';

export interface Application extends OHNElement {
  menu_elements: [OHNElement],
  home_page: string
}
 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html' 
})

export class AppComponent {

  app : Application;
  me : OHNUser

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private router: Router,
    public modalController: ModalController
  ) {
    this.ohnApi.tokenReceivedFromStorage.subscribe(received => {
      if (received) this.getMe();
    });
    this.initializeApp();
  }
 
  initializeApp() {
    this.authenticationService.authenticationState.subscribe(state => {
        if (!state) this.router.navigate(['login']);
    });
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  getMe(){
    this.ohnApi.getMe().subscribe(me => {
      this.loadAppByRole(me);
    });
  }

  loadAppByRole(me: OHNUser) {
    this.me = me;
    //if (me.roles.length > 1) {
     // this.getSubApp('chop_patient', me);
    //} else {
    //  this.getApp();
    //}
    this.getApp();
  }

  getSubApp(role: string, user: OHNUser) {
    this.ohnApi.getElement('app_' + role, 2).subscribe(application => {
      this.app = application;
      if (this.app.config && this.app.config.disclaimer && !user.timezone) {
        this.openDisclaimerModal(this.app.config.disclaimer);
      } else {
        this.loadPageComponent(this.getHomePage());
      }
    });
  }

  getApp() {
    this.ohnApi.getApp().subscribe(application => {
      this.app = application;
        this.loadPageComponent(this.getHomePage());
    });
  }

  getHomePage() {
    return _.find(this.app.elements, (e)=>{return e.element_slug == this.app.home_page})
  }

  loadPageComponent(element: OHNElement) {
    this.router.navigate(['secured', element.controller, element.element_slug]);
  }

  async openDisclaimerModal(text: string) {
    const disclaimer = await this.modalController.create({
      component: DisclaimerComponent,
      componentProps: {
        'text' : text
      },
      cssClass: 'modal-fullscreen',
      backdropDismiss: false
    });

    disclaimer.onDidDismiss().then((data) => {
      //console.log(data.data.config);
      //this.ohnApi.setElement('app_chop_patient', data.data.config).subscribe();
     if (data.data && data.data.accepted) {
       this.saveTimezone();
       this.loadPageComponent(this.getHomePage());
     } else {
       this.authenticationService.logout();
     }
    })

    return await disclaimer.present();
  }

  saveTimezone(){
    console.log('patching user');
    this.me.timezone = new Date().getTimezoneOffset();
    this.ohnApi.patchUser(this.me).subscribe();
  }
}