import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement } from '../../models/ohn-instances';

@Component({
  selector: 'app-calendar-event-result',
  templateUrl: './calendar-event-result.page.html',
  styleUrls: ['./calendar-event-result.page.scss'],
})
export class CalendarEventResultPage implements OnInit {

	@Input() event : OHNCalendarEvent;
	@Input() calendarElement : OHNElement;
 
  constructor(
  	public modalController: ModalController
  ) { }

  ngOnInit() {
  }

  closeModal() {
		this.modalController.dismiss({
			dismissed: true
		});
	}

}
