import { Component, OnInit, Input} from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement ,OHNCalendarAction, OHNCalendarActionList } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-new-calendar-event',
  templateUrl: './new-calendar-event.page.html',
  styleUrls: ['./new-calendar-event.page.scss'],
})
export class NewCalendarEventPage implements OnInit {

	@Input() date: Date;
	@Input() user: OHNUser;
	@Input() calendarElement : OHNElement;
  @Input() isNew: boolean;
  @Input() editEvent: OHNCalendarEvent;


	event: OHNCalendarEvent;

  presetActions: OHNCalendarActionList[] = [];
  currentPresetActionIndex: number;

	startTimeString: string;
	endTimeString: string;

	eventModified: boolean = false;
	loading: boolean = false;


  constructor(
    public modalController: ModalController,
    public toastController: ToastController,
    private ohnApi: OhnApiService,
    private ohnService: OhnService
  ){
  }

  ngOnInit() {
  	this.loadDetailedCalendarElement();
    this.event = this.editEvent || new OHNCalendarEvent();
    if (this.isNew){
      this.startTimeString = this.date.toISOString();
      this.endTimeString = this.date.toISOString();
    } else {
      this.startTimeString = this.event.startTime.toISOString();
      this.endTimeString = this.event.endTime.toISOString();
    } 
  }

  onChange(){
  	console.log(this.event)
  }

  loadDetailedCalendarElement(){
  	this.loading = true;
  	this.ohnApi.getElement(this.calendarElement.element_slug, 3).subscribe(calendar => {
  		this.calendarElement = calendar;
      this.getPresetActions();
  		this.loading = false;
  	});
  }

  getPresetActions() {

    if (this.calendarElement.config.presetActionsSlug) {
      this.ohnApi.getElement(this.calendarElement.config.presetActionsSlug, 1).subscribe(actions => {
        if (actions) {
          this.presetActions = JSON.parse(actions.content)
          console.log(this.presetActions);
        }
      });
    }
  }

  savePresetActions() {
    if (this.calendarElement.config.presetActionsSlug) {
      this.notifyOfSaved();
      let currentActionListIndex: number = _.findIndex(this.presetActions, (a)=>{ return a.text == this.event.contentObject.text;});
      console.log(currentActionListIndex);
      if (currentActionListIndex == -1) {
        if (this.event.contentObject.text != '') {
          this.presetActions.push(this.ohnService.getObjectCopy(this.event.contentObject));
        }
      } else {
        this.presetActions.splice(currentActionListIndex, 1, this.event.contentObject);
      }
      console.log(this.presetActions);
      this.ohnApi.setElement(this.calendarElement.config.presetActionsSlug, {content : JSON.stringify(this.presetActions)}).subscribe(actions => {

      });
    }
  }

  save(){
  	this.loading = true;
    this.event.mergeDateAndTimes(this.date, new Date(this.startTimeString), new Date(this.endTimeString));
    this.event.content = JSON.stringify(this.event.contentObject);
    if (this.isNew) {    
      this.ohnApi.setElementStateSc(this.calendarElement.element_slug, {value: this.event}, this.user.smart_contract).subscribe(event => {
        this.event = new OHNCalendarEvent(event);
        this.loading = false;
        this.eventModified = true;
        this.notifyOfSaved();
      });
    } else {
      this.ohnApi.patchElementState(this.calendarElement.element_slug, {value: this.event}).subscribe(state => {
        this.loading = false;
        this.eventModified = true;
        this.notifyOfSaved();
      });
    }
  	
    
  }

  copyFromPreset() {
    this.event.contentObject = this.ohnService.getObjectCopy(this.presetActions[this.currentPresetActionIndex]);
    console.log(this.event);
  }

  async notifyOfSaved() {
  	const toast = await this.toastController.create({
      message: 'Event has been saved.',
      duration: 2000
    });
    toast.present();
  }

  closeModal() {
  	if (this.eventModified) {
  		this.modalController.dismiss({
	      dismissed: true,
	      event: this.event,
        isNew: this.isNew
	    });
  	} else {
  		this.modalController.dismiss({
	      dismissed: true
	    });
  	}
  }
}
