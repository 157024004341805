import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';

@Component({
  selector: 'app-calendar-event-view',
  templateUrl: './calendar-event-view.page.html',
  styleUrls: ['./calendar-event-view.page.scss'],
})
export class CalendarEventViewPage implements OnInit {

	@Input() event : OHNCalendarEvent;
	@Input() calendarElement : OHNElement;

	loading: boolean = false;

  constructor( 
		public modalController: ModalController,
		private ohnApi: OhnApiService
	){
	}

  ngOnInit() {
  	
  }

  complete() {
  	this.saveCompletion();
  }

  saveCompletion() {
    this.event.content = JSON.stringify(this.event.contentObject);
  	this.ohnApi.patchElementState(this.calendarElement.element_slug, {value: {id : this.event.id, completed: true, content: this.event.content}}).subscribe(state => {
  		console.log(state);
    });
  	this.event.completed = true;
  }

  closeModal() {
		this.modalController.dismiss({
			dismissed: true
		});
	}

}
