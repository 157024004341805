import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController, PopoverController } from '@ionic/angular';
import { OHNUser, OHNCalendarEvent, OHNElement, OHNCalendarAction, OHNCalendarActionList, OHNCalendarSequence } from '../../models/ohn-instances';
import { OhnApiService } from '../../services/ohn-api.service';
import { OhnService } from '../../services/ohn.service';
import { PopoverComponent } from '../../components/basic/popover/popover.component';
import * as _ from 'underscore/underscore';

@Component({
  selector: 'app-calendar-sequence-manager',
  templateUrl: './calendar-sequence-manager.page.html',
  styleUrls: ['./calendar-sequence-manager.page.scss'],
})
export class CalendarSequenceManagerPage implements OnInit {

	@Input() calendarElement : OHNElement;

	editingCalendarAction : OHNCalendarActionList;

	calendarActions : OHNCalendarActionList[] = [];

	calendarSequence : OHNCalendarSequence;

  currentPresetActionIndex : number;

	calendarSequenceList : OHNCalendarSequence[] = [];

  segmentManage: string = 'sequences';

	numberOfWeeks : number = 1;

	isCloning : boolean = false;

	isNewAction : boolean = false;

	isNewSequence : boolean = false;

	loading: boolean = false;

  constructor(
  	private modalController : ModalController,
  	private ohnApi: OhnApiService,
  	private ohnService: OhnService,
  	private toastController: ToastController,
  	public popoverController: PopoverController
  ){ }

  loadDetailedCalendarElement(){
  	this.loading = true;
  	this.ohnApi.getElement(this.calendarElement.element_slug, 3).subscribe(calendar => {
  		this.calendarElement = calendar;
      this.getCalendarActionList();
      this.getCalendarActionSequenceList();
  		this.loading = false;
  	});
  }

  ngOnInit() {
  	this.loadDetailedCalendarElement();
  }

  getCalendarActionList() {
  	if (this.calendarElement.config.presetActionsSlug) {
      this.ohnApi.getElement(this.calendarElement.config.presetActionsSlug, 1).subscribe(actions => {
        if (actions.content) {
          this.calendarActions = JSON.parse(actions.content);
          console.log(this.calendarActions);
        }
      });
    }
  }

  getCalendarActionSequenceList() {
  	if (this.calendarElement.config.presetActionSequences) {
      this.ohnApi.getElement(this.calendarElement.config.presetActionSequences, 1).subscribe(sequences => {
        if (sequences.content) {
          this.calendarSequenceList = JSON.parse(sequences.content);
          console.log(this.calendarSequenceList);
        }
      });
    }
  }

  cloneAction() {
    this.notifyOfSaved('Action Cloned');
    this.isCloning = true;
    let currentAction : OHNCalendarActionList = this.ohnService.getObjectCopy(this.editingCalendarAction);
    this.editingCalendarAction = currentAction;
    this.editingCalendarAction.id = OhnService.getUniqueId(this.calendarActions);
    this.editingCalendarAction.text += " Clone";
  }

  selectAction(idx: number) {
  	this.isNewAction = false;
  	this.editingCalendarAction = this.calendarActions[idx];
  }
/*
  selectAction(event: any) {
    this.isNewAction = false;
    this.editingCalendarAction = this.calendarActions[event.detail.value];
  }
  */

  deleteActionList() {
    this.calendarActions = _.filter(this.calendarActions, (a)=>{ return a.id != this.editingCalendarAction.id;});
    this.ohnApi.setElement(this.calendarElement.config.presetActionsSlug, {content : JSON.stringify(this.calendarActions)}).subscribe(actions => {
        this.notifyOfSaved('Actions deleted');
    });
    this.editingCalendarAction = undefined;
    this.currentPresetActionIndex = undefined;
    this.isNewAction = false;
    this.isCloning = false;
  }

  selectSequence(idx: number) {
  	this.isNewSequence = false;
  	this.calendarSequence = this.calendarSequenceList[idx];
  	let keys = _.keys(this.calendarSequence.sequence);
  	if (keys && keys.length > 0) {
  		this.numberOfWeeks = Math.ceil(keys[keys.length - 1] / 7);
  	}
  }

/*
  selectSequence(event: any) {
    this.isNewSequence = false;
    this.calendarSequence = this.calendarSequenceList[event.detail.value];
    let keys = _.keys(this.calendarSequence.sequence);
    if (keys && keys.length > 0) {
      this.numberOfWeeks = Math.ceil(keys[keys.length - 1] / 7);
    }
  }
*/

  newAction() {
  	/*_.each(this.calendarActionList, (a)=>{
  		a.id = this.ohnService.getUniqueId(this.calendarActionList);
  	});
  	/*this.editingCalendarAction = <OHNCalendarActionList>{
      text : "",
      icon: "",
      description : [],
      actions : []
    };*/
    this.isNewAction = true;
    this.editingCalendarAction = new OHNCalendarActionList(OhnService.getUniqueId(this.calendarActions));
  }

  newSequence() {
    this.isNewSequence = true;
    this.calendarSequence = new OHNCalendarSequence(OhnService.getUniqueId(this.calendarSequenceList));
  }
  

  newWeek() {
  	this.numberOfWeeks++;
  }

  saveActionList() {
    if (this.calendarElement.config.presetActionsSlug) {
    	if (this.isCloning || this.isNewAction) {
    		this.calendarActions.push(this.editingCalendarAction);
    	}

      /*_.each(this.calendarActions, (a)=>{
        _.each(a.actions, (ac)=>{
          ac['id'] = OhnService.getUniqueId(a.actions);
          _.each(ac.trackingParameters, (t)=>{
            t['id'] = OhnService.getUniqueId(ac.trackingParameters);
          });
        });
      });
      */

      this.ohnApi.setElement(this.calendarElement.config.presetActionsSlug, {content : JSON.stringify(this.calendarActions)}).subscribe(actions => {
      	this.notifyOfSaved('Actions saved');
      });
    }
  }

  saveSequenceList() {
    
    if (this.calendarElement.config.presetActionSequences) {
    	if (this.isNewSequence) {
    		this.calendarSequenceList.push(this.calendarSequence);
    	}
    	this.isNewSequence = false;
      this.ohnApi.setElement(this.calendarElement.config.presetActionSequences, {content : JSON.stringify(this.calendarSequenceList)}).subscribe(actions => {
      	this.notifyOfSaved('Sequence saved');
      });
    }
  }

  deleteSequence() {
    if (this.calendarElement.config.presetActionSequences) {
    	this.calendarSequenceList = _.filter(this.calendarSequenceList, (s)=>{
    		return s.id && (s.id != this.calendarSequence.id);
    	}); 	
    	console.log(this.calendarSequenceList);
      this.ohnApi.setElement(this.calendarElement.config.presetActionSequences, {content : JSON.stringify(this.calendarSequenceList)}).subscribe(actions => {
      	this.calendarSequence = undefined;
      });
    }
  }

  async showMenu(ev: any, week: number, day : number) {

		const popover = await this.popoverController.create({
			component: PopoverComponent,
			componentProps: {
				inputData: this.calendarActions, 
				dataType : 'actionList'
			},
			event: ev,
			backdropDismiss: true,
			mode: "ios",
			translucent: true
		});

		popover.onDidDismiss().then((res) => {
			if (res.data) {
				this.addActionsToDay(res.data, week, day);
			}
		});

		return await popover.present();
	}

	addActionsToDay(action: OHNCalendarActionList, week: number, day : number) {
		if (this.calendarSequence.sequence[week*7 + day]) {
			this.calendarSequence.sequence[week*7 + day].push(action.id);	
		} else {
			this.calendarSequence.sequence[week*7 + day] = [action.id];
		}
	}

	getActionTitleById(id: string){
		return _.find(this.calendarActions, (a)=>{return a.id == id});
	}

	deleteFromActionSequence(id: string, index: number){
		this.calendarSequence.sequence[index] = _.filter(this.calendarSequence.sequence[index], (a)=>{
			return a != id;
		});
	}

  closeModal() {
		this.modalController.dismiss({
			dismissed: true
		});
  }

  async notifyOfSaved(text: string) {
  	const toast = await this.toastController.create({
      message: text,
      duration: 2000
    });
    toast.present();
  }

}
