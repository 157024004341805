import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';

@Component({
  selector: 'app-exercise-secuence-constructor',
  templateUrl: './exercise-secuence-constructor.component.html',
  styleUrls: ['./exercise-secuence-constructor.component.scss'],
})
export class ExerciseSecuenceConstructorComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {}

}
