import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-image-element-config',
  templateUrl: './image-element-config.component.html',
  styleUrls: ['./image-element-config.component.scss'],
})
export class ImageElementConfigComponent implements OnInit {
	
	@Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
