import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-yes-no-input-element',
  templateUrl: './yes-no-input-element.component.html',
  styleUrls: ['./yes-no-input-element.component.scss'],
})
export class YesNoInputElementComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {}

}
