import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-weight-input-element',
  templateUrl: './weight-input-element.component.html',
  styleUrls: ['./weight-input-element.component.scss'],
})
export class WeightInputElementComponent implements OnInit {

	@Input() el: OHNElement;
	@Input() viewMode : string;

  constructor() { }

  ngOnInit() {}

}
